import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import DefaultLayout from 'layouts/Default'
import Hero from 'components/pg-home/Hero'
import Block from 'components/blocks/Block'
import StructuredData from 'components/StructuredData'

const HomePg = () => {
	const { sanityHomePg: pg } = useStaticQuery(query)

	const { ref, inView } = useInView({
		rootMargin: '-64px 0px 0px 0px',
	})

	return (
		<DefaultLayout {...pg.seo} transparentHeader={{ ref, inView }}>
			<Hero {...pg.hero} ref={ref} />

			{pg.blocks?.map((block) => (
				<Block {...block} key={block?._key} />
			))}

			<StructuredData />
		</DefaultLayout>
	)
}

export default HomePg

const query = graphql`
	query HomePg {
		sanityHomePg {
			hero {
				title
				_rawDescription
				link {
					...link
				}
				video {
					filename
					poster {
						asset {
							gatsbyImageData(width: 400)
						}
					}
				}
				location
			}
			blocks {
				... on SanityBlockGroup {
					_key
					_type
					style
					blocks {
						...interactiveMap
						...statList
					}
				}
				...expertiseList
				...heroGrayed
				...heroSplit
				...imageAndTextCenteredSections
				...imageAndTextFeatured
				...logoListScroll
				...textWithBg
			}
			seo {
				title
				description
			}
		}
	}
`
